<template>
    <select id="dashboards" v-model="activeDashboard" @change="switchBoard">
        <option
        v-for="dashboard in dashboards"
        :key="dashboard.id"
        :value="dashboard.id"
        >{{ dashboard.name }}</option>

    </select>
</template>

<script>
export default {
    data() {
        return {
            dashboards: this.$store.getters['dashboard/dashboards'],
            activeDashboard: this.$store.getters['dashboard/activeDashboard'],
        }
    },
    methods: {
        switchBoard(event) {
            const id = event.target.value;
            this.$store.dispatch('dashboard/setActiveDashboardAndTab', {
                id: id, 
                instanceID: this.$root.instanceID
            });
            this.$emit('dashboard-switch', id);

        }
    }
}
</script>

<style scoped>
#dashboards {
    display: inline-block;
    width: 150px;
    background: #ffd;
    border: 1px solid black;
    margin-top: 10px;
    padding: 10px;
    color: black;
    text-decoration: none;
    box-sizing: content-box;
}
</style>
